import React from 'react'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Link } from 'react-router-dom'
import { useGetBrandsQuery } from "../../store/services/brandService";

const NearByBusiness = () => {


    const { data: brandsdata, isLoading, error } = useGetBrandsQuery();
    const brands = brandsdata?.brands;

    let i = 1;
    return (
        brands?.length > 0 && (
            <Swiper
                navigation={true}
                modules={[Navigation]}
                spaceBetween={10}
                slidesPerView={20}
                Navigation
                className="w-full h-full"
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },
                    768: {
                        slidesPerView: 1,
                    },
                    1080: {
                        slidesPerView: 4,
                    },
                    1280: {
                        slidesPerView: 4,
                    },
                }}
            >
                {brands.map((category, index) => {
                    if (i >= 5) {
                        i = 1;
                    } else {
                        i++;
                    }
                    return (
                        <SwiperSlide
                            className="w-full h-full overflow-hidden  relative"
                            key={index}
                            virtualIndex={index}
                        >
                            <Link
                                to={{
                                    pathname: `/commericaldetails/${category._id}`,

                                }}
                                state={{
                                    maindata: category
                                }}
                                className="w-full h-full"
                            >
                                <div class="container custom-container-two">
                                    <div class="product__item-wrap-two">
                                        <div class="p-2">

                                            <div class="col">
                                                <div class="product__item">
                                                    <div class="product__thumb">
                                                        <Link to={`/commericaldetails/${category._id}`} state={{ maindata: category }}><img src={`https://api.petzoy.in/assets/${category?.picturePath}`} alt="img" /></Link>

                                                        <div class="sale-wrap sale-wrap-two">
                                                            <span>Near You !</span>
                                                        </div>
                                                        <div class="product__add-cart">
                                                            <Link to={`/commericaldetails/${category._id}`} state={{ maindata: category }} class="btn"><i class="flaticon-eye"></i>View More</Link>
                                                        </div>
                                                    </div>
                                                    <div class="product__content">
                                                        <div class="product__reviews">
                                                            <div class="rating">
                                                                <i class="fas fa-star"></i>
                                                                <i class="fas fa-star"></i>
                                                                <i class="fas fa-star"></i>
                                                                <i class="fas fa-star"></i>
                                                                <i class="fas fa-star"></i>
                                                            </div>
                                                            <span>(2 Reviews)</span>
                                                        </div>
                                                        <h3 class="title"><Link to={`/commericaldetails/${category._id}`} state={{ maindata: brands }}>{category.name}</Link></h3>
                                                        <h3 class="price"><i class="fa-solid fa-location-dot" /> 
                                                        <span class="truncate">{category?.address}</span>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* {!isLoading &&
                                                brands?.map((brand) => {
                                                    return (
                                                        <div class="col">
                                                            <div class="product__item">
                                                                <div class="product__thumb">
                                                                    <Link to="/commericaldetails" state={{ maindata: brand }}><img src={`https://api.petzoy.in/assets/${brand?.picturePath}`} alt="img" /></Link>

                                                                    <div class="sale-wrap sale-wrap-two">
                                                                        <span>Near You !</span>
                                                                    </div>
                                                                    <div class="product__add-cart">
                                                                        <Link to="/commericaldetails" state={{ maindata: brand }} class="btn"><i class="flaticon-eye"></i>View More</Link>
                                                                    </div>
                                                                </div>
                                                                <div class="product__content">
                                                                    <div class="product__reviews">
                                                                        <div class="rating">
                                                                            <i class="fas fa-star"></i>
                                                                            <i class="fas fa-star"></i>
                                                                            <i class="fas fa-star"></i>
                                                                            <i class="fas fa-star"></i>
                                                                            <i class="fas fa-star"></i>
                                                                        </div>
                                                                        <span>(2 Reviews)</span>
                                                                    </div>
                                                                    <h4 class="title"><Link to="/commericaldetails" state={{ maindata: brand }}>{brand.name}</Link></h4>
                                                                    <h3 class="price"><i class="fa-solid fa-location-dot" /> {brand?.location}</h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })} */}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        )
    )
}

export default NearByBusiness