import React, { useEffect, useRef, useState } from "react";
import WhiteHeader from "../../components/WhiteHeader";
import { useDispatch, useSelector } from "react-redux";
import { useGetUserByIdQuery } from "../../store/services/authService";
import UserImage from "../../components/UserImage";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import ServiceListWidget from "../widgets/ServiceListWidget";
import ProfilePetsWidget from "../widgets/ProfilePetsWidget";
import EditProfileModal from "../widgets/EditProfileModal";
import { useGetBrandsByUserQuery } from "../../store/services/brandService";
import ProfileCommercialWidget from "../widgets/CommercialWidgets/ProfileCommericalWidgets";
import { logout } from "../../store/reducers/authReducer";
import { useVerifyPaymentQuery } from "../../store/services/paymentService";
import { emptyCart } from "../../store/reducers/cartReducer";
import toast from "react-hot-toast";
import UserOrderDetails from "../payment/UserOrderDetails";
import {
  useGetOrdersQuery,
  useReceivedOrderMutation,
} from "../../store/services/userOrdersService";
import currency from "currency-formatter";
import { discount } from "../../utils/discount";
import "./profile.css";
import {
  useAddAddressMutation,
  useGetUserAddressesQuery,
  useUpdateAddressMutation,
} from "../../store/services/addressService";
import EditAddressModal from "../widgets/AddressWidgets/EditAddressModal";
import { useGetFollowerUsersQuery, useGetFollowingUsersQuery } from "../../store/services/FriendRequestService";
import EditProfileImg from "../widgets/EditProfileImg";
import Joyride from 'react-joyride';
import { useGetOverlayStepsByPageQuery } from "../../store/services/overlayService";

const EditProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showdemo = useSelector((state) => state.authReducer.demoshowhome);
  const displaylocation = useSelector((state) => state.authReducer.location);
  const [show_editimg, setshow_editimg] = useState(false);
  const { user } = useSelector((state) => state.authReducer);
  const { _id, picturePath } = useSelector((state) => state.authReducer.user);

  const [modal_open, setmodal_open] = useState(false);
  const { data: userdata, error: usererror } = useGetUserByIdQuery(_id);
  const { data, isFetching } = useGetOrdersQuery({ userId: _id });

  const [updateOrder, response] = useReceivedOrderMutation();
  const orderReceived = (id) => {
    updateOrder(id);
  };
  const { data: following, error, isLoading } = useGetFollowingUsersQuery(_id);
  const { data: followers,} = useGetFollowerUsersQuery(_id);

  const [showaddress_modal, setshowaddress_modal] = useState(false);
  const [selectedtab, setselectedtab] = useState(-1);
  const [selectedorder, setselectedorder] = useState("");

  const [params] = useSearchParams();
  const id = params.get("session_id");
  const { data: paydata, isSuccess: paysuccess } = useVerifyPaymentQuery(id, {
    skip: id ? false : true,
  });

  useEffect(() => {
    if (paysuccess) {
      localStorage.removeItem("cart");
      toast.success(paydata.msg);
      dispatch(emptyCart());
      navigate("/editprofile");
    }
  }, [paysuccess]);

  const handleLogout = () => {
    dispatch(logout("userToken"));
    navigate("/"); // Update the route as needed
  };

  const { data: userAddresses, isLoading: isAddressloading } =
    useGetUserAddressesQuery(user._id);




    const { data:story,  } = useGetOverlayStepsByPageQuery('profile');

  return (
    <>
      <EditProfileModal
        open={modal_open}
        setOpen={setmodal_open}
        userdata={userdata}
      />
      <EditProfileImg open={show_editimg} setOpen={setshow_editimg} />
      <EditAddressModal
        open={showaddress_modal}
        setOpen={setshowaddress_modal}
      />
      
      <body>
        <div className="body-container">
          <div className="dashboard">
            <div className="grid-container">

            {!showdemo &&     <Joyride
          steps={story}
          showProgress
        showSkipButton
        scrollToFirstStep
          continuous
        />}
              {/* Account Page - Profile Page */}
              <div className="profile grid-area">
                <div className="img">
                  <img
                    src={`https://api.petzoy.in/assets/${picturePath}`}
                    onClick={() => setshow_editimg(true)}
                  />
                  <div className="button editbtn" onClick={() => setmodal_open(true)}>
                    <i className="fas fa-sliders-h"></i>
                  </div>
                </div>
                <div className="nameEmail">
                  <h3 className="mainName">{userdata?.firstName} </h3>
                  <h5 className="online">{userdata?.email}</h5>
                </div>
                <div className="edit-infos">
                  {/* <h3>Personal information<i className="fas fa-pen"></i></h3> */}
                  <div className="profile-data">
                   {userdata?.phone && <div className="data-details">
                      <h5>Phone</h5>
                      <h5>{userdata?.phone}</h5>
                    </div>}
                   {userdata?.email && <div className="data-details">
                      <h5>Email</h5>
                      <h5>{userdata?.email}</h5>
                    </div>}
                    <div className="data-details">
                      <h5>Location</h5>
                      <h5>{displaylocation?.split(",")[0]}</h5>
                    </div>
                  </div>
                </div>
                <div className="profile-data">
                  <div className="data-details">
                    <h5>Pets</h5>
                    <h4>{userdata?.pets?.length}</h4>
                  </div>
                  <div className="data-details">
                    <h5>Followers</h5>
                    <h4>{userdata?.followers?.length}</h4>
                  </div>
                  <div className="data-details">
                    <h5>Followings</h5>
                    <h4>{userdata?.following?.length}</h4>
                  </div>
                </div>
                <div className="profile-more" onClick={handleLogout}>
                  <button className="logout-btn">
                    Logout
                    <i
                      className="fa-solid fa-right-from-bracket ml-2"
                      style={{ color: "#FF0000" }}
                    ></i>
                  </button>
                </div>
              </div>

              {/* My Pets Section */}
              <div className="last-view grid-area addpets">
                <ProfilePetsWidget userId={_id} />
              </div>

              {/* My Business Section */}
              <div className="last-like grid-area addbuss">
                <ProfileCommercialWidget />
              </div>

              {/* Get Help Section */}
              <div className="edit-profile grid-area">
                <div className="header">
                  <h1>
                    <Link to={"/contactus"}> Get Help ? </Link>
                  </h1>
                </div>
                <div
                  className="profile-picture"
                  onClick={() => setshow_editimg(true)}
                >
                  <img src={`https://api.petzoy.in/assets/${picturePath}`} />
                </div>
                <h1>{userdata?.firstName}</h1>
                {/* <div className="edit-infos">
                  <h3>Personal information<i className="fas fa-pen"></i></h3>
                  <div className="profile-data">
                    <div className="data-details">
                      <h5>Age</h5>
                      <h4>24</h4>
                    </div>
                    <div className="data-details">
                      <h5>Gender</h5>
                      <h4>Woman</h4>
                    </div>
                    <div className="data-details">
                      <h5>Location</h5>
                      <h4>Paris</h4>
                    </div>
                  </div>
                </div> */}
                <div className="edit-interest">
                  <h3>
                    Your Shipping Address ?
                    <i
                      className="fas fa-pen"
                      onClick={() => setshowaddress_modal(true)}
                    ></i>
                  </h3>
                  {userAddresses?.length > 0 && (
                    <>
                      <p className="p-small">
                        {userAddresses[0]?.addressLine1},
                        {userAddresses[0]?.state},{userAddresses[0]?.city},
                        {userAddresses[0]?.postalCode}
                      </p>
                    </>
                  )}
                </div>
                {/* <div className="edit-interest">
                  <h3>Bio<i className="fas fa-pen"></i></h3>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi aliquam leo eu sapien tristique, eu efficitur mi ornare. Aliquam erat volutpat. Pellentesque rhoncus volutpat laoreet.Aliquam erat volutpat. Pellentesque rhoncus volutpat laoreet.Aliquam erat volutpat. Pellentesque rhoncus volutpat laoreet.</p>
                </div> */}
                <div className="edit-interest ">
                  <h3>
                    Your Services{" "}
                    <i
                      className="fas fa-pen addser"
                      onClick={() => setmodal_open(true)}
                    ></i>
                  </h3>
                  <p>
                    {userdata?.providedServices?.map((item, index) => {
                      return <p>#{item.name}</p>;
                    })}
                  </p>
                </div>

              
              </div>
              <div className="matchs grid-area">
              <div className="matchs grid-area">
                <div className="header">
                  <h1>Followings</h1>
                </div>
                <div className="display-matchs checkfollowing">
                  {following?.map((item, index) => {
                    return (
                      <div className="one-match"  onClick={() => {
                        navigate(`/userconnect/${item._id}`,{ state: {userId:item._id}} )
                        
                        navigate(0);
                      }}>
                        <div className="one-match-content">
                          <img
                            src={`https://api.petzoy.in/assets/${item?.picturePath}`}
                          />
                          <div className="match-info">
                            <h4>{item?.firstName}</h4>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="matchs grid-area checkfollower">
                <div className="header">
                  <h1>Followers</h1>
                </div>
                <div className="display-matchs">
                  {followers?.map((item, index) => {
                    return (
                      <div className="one-match"  onClick={() => {
                        navigate(`/userconnect/${item._id}`,{ state: {userId:item._id}} )
                        
                        navigate(0);
                      }}>
                        <div className="one-match-content">
                          <img
                            src={`https://api.petzoy.in/assets/${item?.picturePath}`}
                          />
                          <div className="match-info">
                            <h4>{item?.firstName}</h4>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>



      </body>
    </>
  );
};

export default EditProfile;
