import React, { useState } from "react";
import { useGetProductQuery } from "../../store/services/productService";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import HTMLReactParser from "html-react-parser";
import currency from "currency-formatter";
import { useDispatch, useSelector } from "react-redux";
import { addCart, decQuantity, incQuantity, removeItem } from "../../store/reducers/cartReducer";
import toast from "react-hot-toast";
import { discount } from "../../utils/discount";
import { usePriceonReqMutation } from "../../store/services/contactService";
import { Add, Delete, Remove } from "@mui/icons-material";
const ProductDetails = () => {
  const { mainid } = useParams();
  const { data, isFetching } = useGetProductQuery(mainid);

  const { cart, total, totalshipping } = useSelector((state) => state.cart);
  const cartItem = cart.find((item) => item._id === mainid);

  const [priceonReq, { isLoading }] = usePriceonReqMutation();

  const [loading, setloading] = useState(false)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);
  const [link_copied, setlink_copied] = useState(false);
  const [sizeState, setSizeState] = useState(
    data?.sizes?.length > 0 && data.sizes[0].name
  );
  const [colorState, setColorState] = useState(
    data?.colors?.length > 0 && data.colors[0].color
  );

  const _id = useSelector(
    (state) => state.authReducer.user?._id
  );

  const addToCart_apihit = () => {
    const {
      ["colors"]: colors,
      ["sizes"]: sizes,
      ["createdAt"]: createdAt,
      ["updatedAt"]: updatedAt,
      ...newProduct
    } = data;
    newProduct["size"] = sizeState;
    newProduct["color"] = colorState;
    newProduct["quantity"] = quantity;
    newProduct["shipping"] = data.shipping;
    const cart = localStorage.getItem("cart");
    const cartItems = cart ? JSON.parse(cart) : [];
    const checkItem = cartItems.find((item) => item._id === newProduct._id);
    if (!checkItem) {
      dispatch(addCart(newProduct));
      cartItems.push(newProduct);
      toast.success(`${newProduct.title} Added in cart`);
      localStorage.setItem("cart", JSON.stringify(cartItems));
    } else {
      console.log("not ok");
      toast.error(`${newProduct.title} is already in cart`);
      return;
    }
  };
  const { userToken, user } = useSelector((state) => state.authReducer);
  const donation_transfer = () => {
    if (!userToken) {
      navigate("/signin");
      return;
    }

    const {
      ["colors"]: colors,
      ["sizes"]: sizes,
      ["createdAt"]: createdAt,
      ["updatedAt"]: updatedAt,
      ...newProduct
    } = data;
    newProduct["size"] = sizeState;
    newProduct["color"] = colorState;
    newProduct["quantity"] = quantity;
    newProduct["shipping"] = data.shipping;
    navigate("/commerical", { state: { cart: newProduct, multiple: false } });
  };

  const buynow_apihit = () => {
    const {
      ["colors"]: colors,
      ["sizes"]: sizes,
      ["createdAt"]: createdAt,
      ["updatedAt"]: updatedAt,
      ...newProduct
    } = data;
    newProduct["size"] = sizeState;
    newProduct["color"] = colorState;
    newProduct["quantity"] = quantity;
    newProduct["shipping"] = data.shipping;
    const cart = localStorage.getItem("cart");
    const cartItems = cart ? JSON.parse(cart) : [];
    const checkItem = cartItems.find((item) => item._id === newProduct._id);
    if (!checkItem) {
      dispatch(addCart(newProduct));
      cartItems.push(newProduct);
      toast.success(`Processing to Payment`);
      localStorage.setItem("cart", JSON.stringify(cartItems));
      navigate("/paymentgateway");
    } else {
      console.log("not ok");
      toast.error(`${newProduct.title} is already in cart`);
      return;
    }
  };


  const handleIncrement = (productId) => {
    dispatch(incQuantity(productId));
  };

  const handleDecrement = (productId) => {
    dispatch(decQuantity(productId));
  };

  const handleRemove = (id) => {
    dispatch(removeItem(id));
  };


  return (
    <>
      <main class="fix">
        <section class="product__details-area">
          <div class="container">
            <div class="row">
              <div class="col-lg-6">
                <div class="product__details-images-wrap">
                  <div class="tab-content" id="myTabContent">
                    <div
                      class="tab-pane show active"
                      id="itemOne-tab-pane"
                      role="tabpanel"
                      aria-labelledby="itemOne-tab"
                      tabindex="0"
                    >
                      <a
                        href={`https://api.petzoy.in/assets/${data?.images[0]}`}
                        class="popup-image"
                      >
                        <img
                          src={`https://api.petzoy.in/assets/${data?.images[0]}`}
                          alt="img"
                        />
                      </a>
                    </div>
                    <div
                      class="tab-pane"
                      id="itemTwo-tab-pane"
                      role="tabpanel"
                      aria-labelledby="itemTwo-tab"
                      tabindex="0"
                    >
                      <a
                        href={`https://api.petzoy.in/assets/${data?.images[1]}`}
                        class="popup-image"
                      >
                        <img
                          src={`https://api.petzoy.in/assets/${data?.images[1]}`}
                          alt="img"
                        />
                      </a>
                    </div>
                    <div
                      class="tab-pane"
                      id="itemThree-tab-pane"
                      role="tabpanel"
                      aria-labelledby="itemThree-tab"
                      tabindex="0"
                    >
                      <a
                        href={`https://api.petzoy.in/assets/${data?.image3}`}
                        class="popup-image"
                      >
                        <img
                          src={`https://api.petzoy.in/assets/${data?.image3}`}
                          alt="img"
                        />
                      </a>
                    </div>
                    {/* <div class="tab-pane" id="itemFour-tab-pane" role="tabpanel" aria-labelledby="itemFour-tab" tabindex="0">
                            <a href="assets/img/products/products_img04.jpg" class="popup-image">
                                <img src="assets/img/products/products_img04.jpg" alt="img"/>
                            </a>
                        </div> */}
                  </div>
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    {data?.image1 && (
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link active"
                          id="itemOne-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#itemOne-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="itemOne-tab-pane"
                          aria-selected="true"
                        >
                          <img
                            src={`https://api.petzoy.in/assets/${data?.image1}`}
                            alt="img"
                          />
                        </button>
                      </li>
                    )}
                    {data?.image2 && (
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link"
                          id="itemTwo-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#itemTwo-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="itemTwo-tab-pane"
                          aria-selected="false"
                        >
                          <img
                            src={`https://api.petzoy.in/assets/${data?.image2}`}
                            alt="img"
                          />
                        </button>
                      </li>
                    )}
                    {data?.image3 && (
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link"
                          id="itemThree-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#itemThree-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="itemThree-tab-pane"
                          aria-selected="false"
                        >
                          <img
                            src={`https://api.petzoy.in/assets/${data?.image3}`}
                            alt="img"
                          />
                        </button>
                      </li>
                    )}
                    {/* <li class="nav-item" role="presentation">
                            <button class="nav-link" id="itemFour-tab" data-bs-toggle="tab" data-bs-target="#itemFour-tab-pane" type="button" role="tab" aria-controls="itemFour-tab-pane" aria-selected="false">
                                <img src="assets/img/products/products_img04.jpg" alt="img"/>
                            </button>
                        </li> */}
                  </ul>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="product__details-content">
                  <span class="tag">{data?.category}</span>
                  <h2 class="title">{data?.title}</h2>
                  <div class="product__reviews-wrap">
                    <div class="product__reviews">
                      <div class="rating">
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                      </div>
                      <span>({data?.reviews.length} Reviews)</span>
                    </div>
                    {/* <div class="product__code">
                            <span>SKU: <strong>CAT4502</strong></span>
                        </div> */}
                  </div>
                  <h4 class="price">
                    {" "}
                    {currency.format(discount(data?.price, data?.discount), {
                      code: "INR",
                    })}
                  </h4>
                  {/* <p>{HTMLReactParser(data?.description)}</p> */}
                  {data?.sizes.length > 0 && (
                    <div class="product__size-wrap">
                      <span class="size-title">Size: </span>
                      <ul class="list-wrap">
                        {data?.sizes.map((item, index) => {
                          return (
                            <li key={item.name}>
                              <button
                                style={{
                                  backgroundColor:
                                    item.name === colorState
                                      ? "black"
                                      : "white",
                                  color:
                                    item.name === colorState
                                      ? "white"
                                      : "black",
                                }}
                                onClick={() => setColorState(item.name)}
                              >
                                {item.name}
                              </button>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                  {data?.priceonreq && (
                    <button
                        class="buy-btn bg-gradient-to-r from-purple-500 to-purple-700 mt-2 mb-2"
                        disabled={loading}
                        onClick={async () => {
                            setloading(true)
                          const response = await priceonReq({
                            userId: _id,
                            productId:mainid,
                          });
                          if (response.error) {
                              // Handle error response
                              toast.error("Error:", response.error);
                              setloading(false)
                        } else {
                            // Clear form fields and set success message
                            toast.success(
                                `Price Requested. Further details will be sent to you by your email.`
                            );
                            setloading(false)
                          }
                        }}
                      >
                        {loading ? <>
                            Requesting Exclusive Price <div class="smallbtnloader"></div>
                        </>:"Price on Request"}
                        
                      </button>
                  )}
                  {data?.stock == 0 ? (
                    <>
                      <button class="buy-btn bg-gradient-to-r from-gray-500 to-gray-700 mb-1">
                        Out of Stock
                      </button>
                      
                    </>
                  ) : (
                    <div class="product__details-qty">
                    { cartItem ? (   <div className="flex items-center space-x-2">
                            {cartItem.quantity > 1 ? (
                              <button
                                onClick={() => handleDecrement(mainid)}
                                className="bg-gray-200 text-gray-700 p-1 rounded-full"
                              >
                                <Remove className="w-4 h-4" />
                              </button>
                            ) : (
                              <button
                                onClick={() => handleRemove(mainid)}
                                className="bg-red-500 text-white p-1 rounded-full"
                              >
                                <Delete className="w-4 h-4" />
                              </button>
                            )}
                            <span className="font-bold">{cartItem.quantity}</span>
                            <button
                              onClick={() => handleIncrement(mainid)}
                              className="bg-purple-500 text-white p-1 rounded-full"
                            >
                              <Add className="w-4 h-4" />
                            </button>
                          </div>):
                      <button
                        type="button"
                        onClick={() => addToCart_apihit()}
                        class="add-btn"
                      >
                        Add To Cart
                      </button>}
                    </div>
                  )}

                  {data?.stock != 0 && (
                    <button
                      onClick={() => {
                        donation_transfer();
                      }}
                      class="buy-btn"
                    >
                      Donate to Pets
                    </button>
                  )}
                  {/* <div class="product__wishlist-wrap">
                        <a onClick={() => donation_transfer()} ><i class="flaticon-love"></i>Donate to Pets</a>
                        
                    </div> */}
                  <div class="product__details-bottom">
                    <ul class="list-wrap">
                      <li class="product__details-category">
                        <span class="title">Categories:</span>
                        <a href="product-details.html">{data?.category},</a>
                        {/* <a href="product-details.html">Food,</a>
                                <a href="product-details.html">Care</a> */}
                      </li>
                      {data?.brandName && (
                        <li class="product__details-tags">
                          <span class="title">Brand :</span>
                          <a href="product-details.html">{data?.brandName}</a>
                          {/* <a href="product-details.html">Pet Essentials</a> */}
                        </li>
                      )}
                      <li className="product__details-social">
                        <span className="title">Share :</span>
                        <ul className="list-wrap" id="list-wrap">
                          <li>
                            <a
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  `https://www.petzoy.in/product_details/${mainid}`
                                );
                                toast.success("Link Copied !");
                                setlink_copied(true);
                              }}
                            >
                              <i
                                class={
                                  link_copied
                                    ? "fa-regular fa-circle-check"
                                    : "fa-regular fa-copy"
                                }
                              ></i>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div class="product__details-checkout">
                    <span class="title">Guaranteed Safe Checkout</span>
                    <img src="assets/img/products/card.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="product-desc-wrap">
                  <ul class="nav nav-tabs" id="myTab2" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link active"
                        id="description-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#description-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="description-tab-pane"
                        aria-selected="true"
                      >
                        Description
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="reviews-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#reviews-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="reviews-tab-pane"
                        aria-selected="false"
                      >
                        Reviews
                      </button>
                    </li>
                  </ul>
                  <div class="tab-content" id="myTabContent2">
                    <div
                      class="tab-pane fade show active"
                      id="description-tab-pane"
                      role="tabpanel"
                      aria-labelledby="description-tab"
                      tabindex="0"
                    >
                      {data?.description && (
                        <p>{HTMLReactParser(data?.description)}</p>
                      )}
                    </div>
                    <div
                      class="tab-pane fade"
                      id="reviews-tab-pane"
                      role="tabpanel"
                      aria-labelledby="reviews-tab"
                      tabindex="0"
                    >
                      <div class="product-desc-review">
                        <div class="product-desc-review-title mb-15">
                          <h5 class="title">Customer Reviews (0)</h5>
                        </div>
                        <div class="left-rc">
                          <p>No reviews yet</p>
                        </div>
                        <div class="right-rc">
                          <a href="#">Write a review</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default ProductDetails;
