import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import PostWidget from "./PostWidget";
import { setPosts } from "../../store/reducers/authReducer";

const UserPostsWidget = ({ userId, isProfile = false, userdata, mediaType ,mainid,selected_business}) => {
  const dispatch = useDispatch();
  const posts = useSelector((state) => state.authReducer.posts);
  const token = useSelector((state) => state.authReducer.token);

  

  const getMediaPosts = async () => {
    const response = await fetch(
      `https://api.petzoy.in/posts/${mainid}/posts`,
      {
        method: "GET",
        // headers: { Authorization: `Bearer ${token}` },
      }
    );
    const data = await response.json();
    dispatch(setPosts({ posts: data }));
  };
  

  useEffect(() => {
    
      getMediaPosts();
   
  }, [mainid]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {posts.length > 0 &&
       [...posts]
       .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map(
          ({
            _id,
            userId,
            firstName,
            description,
            location,
            mediaPath,
            mediaType,
            userPicturePath,
            likes,
            comments,
            tag,
            postType,
            brandId,
            createdAt
          }) => (
            <PostWidget
              key={_id}
              postId={_id}
              postUserId={userId}
              name={`${firstName}`}
              description={description}
              location={location}
              picturePath={mediaPath}
              mediaType={mediaType}
              userPicturePath={userPicturePath}
              likes={likes}
              comments={comments}
              tag={tag}
              postType={postType}
              brandId={brandId}
              createdAt={createdAt}
            />
          )
        )}
    </>
  );
};

export default UserPostsWidget;
