import { Box, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";

import UserWidget from "../widgets/UserWidget";
import MyPostWidget from "../widgets/MyPostWidget";
import PostsWidget from "../widgets/PostsWidget";
import AdvertWidget from "../widgets/AdvertWidget";
import FriendListWidget from "../widgets/FriendListWidget";
import { useEffect, useState } from "react";
import CreatePetModal from "../widgets/CreatePetModal";
import MyPetListWidget from "../widgets/MyPetListWidget";
import ServiceListWidget from "../widgets/ServiceListWidget";
import Header from "../../components/Header";
import WhiteHeader from "../../components/WhiteHeader";
import FlexBetween from "../../components/FlexBetween";
import Loader from "../../components/Loader";
import MyCommercialListWidget from "../widgets/CommercialWidgets/MyCommercialWidget";
import { useNavigate, useParams } from "react-router-dom";
import { useGetBrandsByUserQuery } from "../../store/services/brandService";
import CommentPostModal from "../widgets/CommentPostModal";
import { useGetNearbyAdvertisementsQuery } from "../../store/services/advertiseService";
import UserPostsWidget from "../widgets/UserPostWidget";
import UserImage from "../../components/UserImage";
import { useGetUserByIdQuery } from "../../store/services/authService";

const UserSocialProfile = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const { _id  } = useParams();
  const { data:userdata, isFetching } = useGetUserByIdQuery(_id);
  const latlong = useSelector((state) => state.authReducer.latlong);
  const { data:ad_data } = useGetNearbyAdvertisementsQuery({ latitude:latlong?.latitude, longitude:latlong?.longitude });

  const [mediatype, setmediatype] = useState("");
  const [location, setLocation] = useState();
  const navigate = useNavigate();
  const [pet_data, setpet_data] = useState(null);
  const [showmainModal, setshowmainModal] = useState(false);
  const [selected_business, setselected_business] = useState(null);
  const [userdataloading, setuserdataloading] = useState(true);


  const socials = {
    linkedin: {
      link: "https://www.linkedin.com/in/christianbarlow",
      label: "LinkedIn",
    },
    twitter: {
      link: "https://twitter.com/TheRealBarlow_",
      label: "Twitter",
    },
    github: {
      link: "https://github.com/Barlow1",
      label: "Github",
    },
    email: {
      link: "/contact",
      label: "Email me!",
    },
  };

  useEffect(() => {
    function addPrevClass(e) {
      var target = e.target;
      if (target.tagName === "svg") {
        // check if it is an icon
        var li = target.parentNode.parentNode;
        var prevLi = li.previousElementSibling;
        if (prevLi) {
          prevLi.className = "prev";
        }

        target.addEventListener(
          "mouseout",
          function () {
            if (prevLi) {
              prevLi.removeAttribute("class");
            }
          },
          false
        );
      }
    }
    if (window && document) {
      document
        .getElementsByClassName("dock")[0]
        ?.addEventListener("mouseover", addPrevClass, false);
    }

    return () => {
      // DESTROY
      document
        .getElementsByClassName("dock")[0]
        ?.removeEventListener("mouseover", addPrevClass, false);
    };
  }, []);

  return (
    <>
      {/* {userdataloading && <Loader/>} */}


      <Box>
        {/* <Navbar /> */}
        <Box
          width="100%"
          padding={isNonMobileScreens ? "2rem 6%" : "0.5rem 2%"}
          display={isNonMobileScreens ? "flex" : "block"}
          gap="0.5rem"
          justifyContent="space-between"
        >
          {isNonMobileScreens && (
            <Box flexBasis={isNonMobileScreens ? "25%" : undefined}>
           

              <div class="sideBarContainer">
                <div class="sidebar left">
                  
                  {/* //profile detail section */}
                  <div className="blog-avatar-wrap" onClick={() => navigate(`/userconnect/${_id}`,{ state: {userId:_id}})}>
                        <div className="blog-avatar-img">
                          
                            <UserImage image={userdata?.picturePath} size="100px"/>
                        </div>
                        <div className="blog-avatar-info">
                            <span className="designation">Author</span>
                            <h4 className="name"><a href="#">{(userdata?.firstName) || 'Admin'}</a></h4>
                            <p>Check Profile</p>
                        </div>
                    </div>
                  
                  
                </div>
              </div>
            </Box>
          )}
          <Box
            flexBasis={isNonMobileScreens ? "42%" : undefined}
            mt={isNonMobileScreens ? undefined : "2rem"}
          >
          


            {/* <MyCommercialListWidget onPress={(data) => console.log(data)} /> */}
            <UserPostsWidget
              mediaType={mediatype}
              mainid={_id}
              userId={pet_data?._id ? pet_data._id : null}
              isProfile={pet_data != null}
              userdata={pet_data}
              selected_business={selected_business}
            />
          </Box>
          {isNonMobileScreens && (
            <Box flexBasis="26%">
              {/* <ServiceListWidget type={'owner'} /> */}
              <Box m="2rem 0" />
              {ad_data?.map((item, index) => {

                return (
                  <AdvertWidget maindata={item}/>
                )
})}

              <Box m="2rem 0" />
              
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default UserSocialProfile;
