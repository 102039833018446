import { SparklesIcon } from "@heroicons/react/outline";
import TweetInput from "./TweetInput";
import Post from "./Post";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import currency from "currency-formatter";
import { discount } from "../../../utils/discount";
import toast from "react-hot-toast";
import {
  addCart,
  decQuantity,
  incQuantity,
  removeItem,
} from "../../../store/reducers/cartReducer";
import { Add, Delete, Remove } from "@mui/icons-material";
const Feed = ({ maindata, maincat }) => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const { user } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const { cart, total, totalshipping } = useSelector((state) => state.cart);

  const addToCart_apihit = (data) => {
    const {
      ["colors"]: colors,
      ["sizes"]: sizes,
      ["createdAt"]: createdAt,
      ["updatedAt"]: updatedAt,
      ...newProduct
    } = data;
    newProduct["size"] = data?.sizes?.length > 0 && data.sizes[0].name;
    newProduct["color"] = data?.colors?.length > 0 && data.colors[0].color;
    newProduct["quantity"] = 1;
    newProduct["shipping"] = data.shipping;
    const cart = localStorage.getItem("cart");
    const cartItems = cart ? JSON.parse(cart) : [];
    const checkItem = cartItems.find((item) => item._id === newProduct._id);
    if (!checkItem) {
      dispatch(addCart(newProduct));
      cartItems.push(newProduct);
      toast.success(`${newProduct.title} Added in cart`);
      localStorage.setItem("cart", JSON.stringify(cartItems));
    } else {
      console.log("not ok");
      toast.error(`${newProduct.title} is already in cart`);
      return;
    }
  };

  const handleIncrement = (productId) => {
    dispatch(incQuantity(productId));
  };

  const handleDecrement = (productId) => {
    dispatch(decQuantity(productId));
  };

  const handleRemove = (id) => {
    dispatch(removeItem(id));
  };

  return (
    <div className="flex-grow xl:w-2/4 xl:ml-[25%] border-l border-r border-gray-200 max-w-full mainprod">
      <div className="flex py-2 px-3 sticky top-0 z-50 bg-white justify-between items-center border-b border-gray-200">
        <h2 className="text-lg sm:text-xl font-bold cursor-pointer">
          Buy {maincat.name}
        </h2>
      </div>

      <section className="product__area-two">
        <div className="container">
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4">
            {maindata.map((product, index) => {
              const cartItem = cart.find((item) => item._id === product._id);
              return (
                <div key={index} className="bg-white rounded-lg p-4 shadow-md">
                  <div className="product__item">
                    <div className="product__thumb relative">
                      <Link to={`/product_details/${product?._id}`}>
                        <img
                          src={`https://api.petzoy.in/assets/${product?.images[0]}` || "assets/img/products/latest_products_img01.jpg"}
                          alt={product.title}
                          className="w-full h-48 object-cover rounded-t-lg"
                        />
                      </Link>
                      <div className="absolute top-2 left-2 bg-green-500 text-white px-2 py-1 rounded-full text-xs">
                        New
                      </div>
                    </div>
                    <div className="product__content mt-4">
                      <div className="flex items-center mb-2">
                        <div className="flex text-yellow-400">
                          {[...Array(5)].map((_, i) => (
                            <i key={i} className="fas fa-star"></i>
                          ))}
                        </div>
                        <span className="text-sm text-gray-600 ml-2">(2 Reviews)</span>
                      </div>
                      <h4 className="title text-lg font-semibold mb-2">
                        <Link to={`/product_details/${product?._id}`}>
                          {product.title}
                        </Link>
                      </h4>
                      <div className="flex justify-between items-center mt-2">
                        <h3 className="price text-lg font-bold">
                          {currency.format(discount(product?.price, product?.discount), { code: "INR" })}
                          {product?.discount != 0 ? (  <del className="ml-2 text-sm text-gray-500">
                            {currency.format(product?.price, { code: "INR" })}
                          </del>):null}
                        </h3>
                        {product?.stock === 0 ? (
                          <button className="bg-gray-500 text-white px-3 py-2 rounded-lg text-sm" disabled>
                            Out of Stock
                          </button>
                        ) : cartItem ? (
                          <div className="flex items-center space-x-2">
                            {cartItem.quantity > 1 ? (
                              <button
                                onClick={() => handleDecrement(product._id)}
                                className="bg-gray-200 text-gray-700 p-1 rounded-full"
                              >
                                <Remove className="w-4 h-4" />
                              </button>
                            ) : (
                              <button
                                onClick={() => handleRemove(product._id)}
                                className="bg-red-500 text-white p-1 rounded-full"
                              >
                                <Delete className="w-4 h-4" />
                              </button>
                            )}
                            <span className="font-bold">{cartItem.quantity}</span>
                            <button
                              onClick={() => handleIncrement(product._id)}
                              className="bg-purple-500 text-white p-1 rounded-full"
                            >
                              <Add className="w-4 h-4" />
                            </button>
                          </div>
                        ) : (
                          <button
                            onClick={() => addToCart_apihit(product)}
                            className="bg-purple-600 hover:bg-purple-700 text-white px-3 py-2 rounded-lg text-sm transition duration-300"
                          >
                            Add To Cart
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Feed;
