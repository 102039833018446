import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  Modal,
  TextField,
  Typography,
  useTheme,
  Checkbox,
  Card,
  CardActions,
  CardContent,
  Switch,
  useMediaQuery,
  styled,
  FormControlLabel,
  MenuItem,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPetsType, setUserPicturePath } from "../../store/reducers/authReducer";
import FlexBetween from "../../components/FlexBetween";
import { useNavigate } from "react-router";
import UserImage from "../../components/UserImage";
import { useUpdateProfileimgMutation, useUpdateProfileMutation } from "../../store/services/authService";
import Cropper from "react-easy-crop";
import Dropzone from "react-dropzone";
import { UploadFile } from "@mui/icons-material";
import toast from "react-hot-toast";
const EditProfileModal = ({ open, setOpen, userdata }) => {
  const { _id, picturePath } = useSelector((state) => state.authReducer.user);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const theme = useTheme();
  const navigate = useNavigate();
  const [formdata, setformdata] = useState({
    name: "",
    email: "",
    occupation: "",
    private: false,
    commercial: false,
  });
  const [selected_type, setselected_type] = useState(null);
  const [preview, setPreview] = useState(null);
  const [imgfile, setimgfile] = useState(null);

  const [current_addstep, setcurrent_addstep] = useState(0);
  const { palette } = useTheme();

  const token = useSelector((state) => state.authReducer.token);
  const petstype = useSelector((state) => state.authReducer.petstype);
  const loggedInUserId = useSelector((state) => state.authReducer.user._id);
  const dispatch = useDispatch();


  const handleOpen = () => setOpen(true);
  const handleClose = () => {

    setPreview(null);
    setformdata({
      name: "",
      email: "",
      occupation: "",
      private: false,
      commercial: false,
    })
    setOpen(false);
  };




  const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      '&::before, &::after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
      '&::before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      '&::after': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
    },
  }));



  const [all_services, setall_services] = useState([])

  const [selectedServiceTypes, setSelectedServiceTypes] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);


  useEffect(() => {
    setformdata({
      name: userdata?.firstName,
      email: userdata?.email,
      occupation: userdata?.occupation,
      private: userdata?.private,
      commercial: userdata?.commercial,
    })
    setSelectedServiceTypes(userdata?.serviceTypesProvided.map(item => ({
      petType: item.petType._id,
    })));
    setSelectedServices(userdata?.providedServices)
  }, [userdata])


  const getallpet_type = async () => {
    const response = await fetch(`https://api.petzoy.in/pets/pet_type/all`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    dispatch(setPetsType({ petstype: data }));
  };


  const getpet_type = async () => {
    const response = await fetch(`https://api.petzoy.in/api/services/services`, {
      method: "GET",
      // headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    const petServices = data.filter(service => service.type == "owner");
    setall_services(petServices);
    // setall_services(data)
  };


  useEffect(() => {
    getpet_type()
    getallpet_type()
  }, [])

 
  const [updateProfileMutation] = useUpdateProfileMutation();

  const handleFormInput = (event) => {
    console.log(event);
    const { name, value } = event.target;
    setformdata({
      ...formdata,
      [name]: value,
    });
  };

  console.log("select type ", selected_type)

  const handleSave = async () => {
    if(!formdata.name){
      toast.error("User name Required");
      return
    }

    try {
      if(imgfile){
        handleImgSave()
      }
      const response = await updateProfileMutation({
        name: formdata.name,
        private: formdata.private,
        email: formdata.email,
        userId: _id,
        occupation: formdata.occupation,
        commercial: formdata.commercial,
        services: JSON.stringify(selectedServices),
        selectedServiceTypes: JSON.stringify(selectedServiceTypes),
      });

      console.log(response.data); // Log the response data

      // Reset form values and close the modal
      setimgfile(null);
      setimgfile("");
      handleClose();
    } catch (error) {
      console.error('Error updating profile:', error);
      // Handle error if needed
    }




    handleClose();
  };



  console.log("selected services", selectedServices, "and selected type", selectedServiceTypes)



  const handleServiceSelection = (service) => {
    const index = selectedServices.findIndex((s) => s._id === service._id);
    if (index === -1) {
      setSelectedServices([...selectedServices, { ...service, title: "" }]);
    } else {
      const updatedServices = [...selectedServices];
      updatedServices.splice(index, 1);
      setSelectedServices(updatedServices);
    }
  };

  const handleTitleChange = (event, serviceId) => {
    const { value } = event.target;
    setSelectedServices((prevServices) =>
      prevServices.map((service) =>
        service._id === serviceId ? { ...service, title: value } : service
      )
    );
  };




  // Function to handle the selection of a pet type
  const handlePetTypeSelection = (petType) => {
    setSelectedServiceTypes((prevSelectedTypes) => {
      const isSelected = prevSelectedTypes.some((type) => type.petType === petType._id);
  
      if (isSelected) {
        // If the pet type is already selected, remove it from the array
        return prevSelectedTypes.filter((type) => type.petType !== petType._id);
      } else {
        // If the pet type is not selected, add it to the array
        return [
          ...prevSelectedTypes,
          {
            petType: petType._id,
          },
        ];
      }
    });
  };
  


  
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const [updateProfile, { isLoading, error }] = useUpdateProfileimgMutation(); // Use the mutation hook

  const handleImageUpload = (files) => {
    const file = files[0];
    if (file) {
      setimgfile(file);
      setPreview(URL.createObjectURL(file));
    }
  };
 
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });

  const getCroppedImg = async (imageSrc, pixelCrop) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      }, 'image/jpeg');
    });
  };

  const handleImgSave = async () => {
    if (!imgfile || !imgfile.type.startsWith("image/")) {
      console.error("Please select a valid image file.");
      return;
    }

    if (!croppedAreaPixels) {
      console.error("No cropped image data available.");
      return;
    }

    try {
        const croppedBlob = await getCroppedImg(preview, croppedAreaPixels);
        const randomName = `croppedImage_${Math.random().toString(36).substring(2, 15)}.jpg`;
        const croppedFile = new File([croppedBlob], randomName, { type: "image/jpeg" });

      const formData = new FormData();
      formData.append("userId", _id);
      formData.append("picture", croppedFile);
      formData.append("picturePath",randomName);

      const result = await updateProfile(formData);
      if (result?.data?.resp === true) {
        dispatch(setUserPicturePath({ picturePath: croppedFile.name }));
      }

      setimgfile(null);
      setPreview(null);
      handleClose();
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };
  

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "scroll",
        bgcolor: "rgba(0,0,0,0)",
        border: "2px solid #000",
        flexDirection: "column",
        boxShadow: 24,
        maxHeight: '100%',

      }}
    >
      <>      {!isNonMobileScreens &&
        <Box
          sx={{
            width: "100%",
            maxWidth: "800px",
            bgcolor: palette.primary.main,
            display: "flex",
            boxShadow: 24,
            height: "70px", alignItems: "center", justifyContent: "space-between", paddingLeft: '10px', paddingRight: '10px'
          }}
        >
          <i onClick={handleClose} class="fa-solid fa-chevron-left"></i>
          <Typography variant="h4" color={"black"}>
            Edit Profile
          </Typography>
          <i class="fa-solid fa-paw"></i>
        </Box>}
        <Box
          sx={{
            width: isNonMobileScreens ? "30%" : "100%",
            maxWidth: "800px",
            bgcolor: "white",

            borderRadius: isNonMobileScreens ? 5 : 0,
            boxShadow: 24,
            height: isNonMobileScreens ? "auto" : "100%", paddingBottom: 1, paddingLeft: 3, paddingRight: 3, overflowY: "scroll", maxHeight: isNonMobileScreens ? "80%" : "100%",
            // marginBottom:isNonMobileScreens? "0%":"20%"
          }}
        >


          <>

          <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
               <Box
              sx={{
                width: "100%",
                height: "300px", // Fixed height for the cropper container
                position: "relative",
                alignSelf: "center",
                alignItems: "center",
                justifyContent: "center",
                borderWidth: 1,
                borderRadius: "10px",
                borderStyle: "dashed",
                overflow: "hidden",
              }}
            >
             <Dropzone
          accept={".jpg,.jpeg,.png"}
          multiple={false}
          onDrop={handleImageUpload}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} style={{ height: "100%", width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <input {...getInputProps()} />
              {preview ? (
                <Cropper
                  image={preview}
                  crop={crop}
                  zoom={zoom}
                  aspect={3 / 4}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  style={{ containerStyle: { width: '100%', height: '100%' } }}
                />
              ) : (
                <>
                  <UploadFile fontSize="large" style={{ fontSize: "48px", marginBottom: "1rem" }} />
                  <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                              Upload or Drop Profile Image Here (only .jpg,.jpeg,.png)
                  </p>
                </>
              )}
            </div>
          )}
        </Dropzone>
            </Box>
               
             
              <Box>
               
              </Box>
            </Box>
          


            <TextField
              label="Full Name"
              value={formdata.name}
              type="text"
              required
              name="name"
              onChange={handleFormInput}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Email"
              value={formdata.email}

              name="email"
              onChange={handleFormInput}
              fullWidth
              margin="normal"
            />

            {/* <TextField
          select
          label="Occupation"
          value={formdata.occupation}
          onChange={handleFormInput}
          name="occupation"
          fullWidth
          margin="normal"
        >
          <MenuItem value="Vetinary">Vetinary</MenuItem>
          <MenuItem value="Shop Owner">Shop Owner</MenuItem>
          <MenuItem value="Personal Business">Personal Business</MenuItem>
          <MenuItem value="NGO Owner">NGO Owner</MenuItem>
        </TextField> */}

            <FlexBetween marginTop={'10px'} marginBottom={'10px'}>
              <FlexBetween gap="1rem" >
                <img
                  style={{ objectFit: "cover", width: "30px", height: "30px" }}

                  alt="user"
                  src={`https://www.freeiconspng.com/thumbs/lock-icon/lock-icon-11.png`}
                />
                <Box

                >
                  <Typography
                    color={"blac"}
                    variant="h5"
                    fontWeight="500"
                    sx={{
                      "&:hover": {
                        color: palette.primary.light,
                        cursor: "pointer",
                      },
                    }}
                  >
                    Private Account
                  </Typography>
                  <Typography color={"#000"} fontSize="0.75rem">
                    Keep my profile private
                  </Typography>
                </Box>
              </FlexBetween>
              <FormControlLabel
                control={<Android12Switch onClick={() => setformdata({ ...formdata, private: !formdata.private })} checked={formdata.private} />}
              // label="Android 12"
              />

            </FlexBetween>


            {/* <FlexBetween marginTop={'10px'} marginBottom={'20px'}>
      <FlexBetween gap="1rem">
      <img
        style={{ objectFit: "cover",width:"30px",height:"30px" }}
       
        alt="user"
        src={`https://emojiisland.com/cdn/shop/products/Dog_Emoji_large.png?v=1571606065`}
      />
        <Box
         
        >
          <Typography
            color={"blac"}
            variant="h5"
            fontWeight="500"
            sx={{
              "&:hover": {
                color: palette.primary.light,
                cursor: "pointer",
              },
            }}
          >
            Commerical Account
          </Typography>
          <Typography color={"#000"} fontSize="0.75rem">
            Am a commerical user (ex: NGO or Shop Owner)
          </Typography>
        </Box>
      </FlexBetween>
      <FormControlLabel
        control={<Android12Switch onClick={() => setformdata({...formdata,commercial:!formdata.commercial})} checked={formdata.commercial} />}
        // label="Android 12"
      />
     
    </FlexBetween> */}



            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // width:"300px",
                // flexDirection: "row",
                // justifyContent: "space-evenly",
                alignItems: "center",

              }}
              gridColumn="span 4"
            >
              {all_services?.map((service) => (
                <>
                  <FlexBetween sx={{ width: "100%", marginTop: "10px" }} key={service._id}>
                    <FlexBetween gap="1rem">
                      <UserImage image={service.image} size="55px" />
                      <Box>
                        <Typography
                          color={'black'}
                          variant="h5"
                          fontWeight="500"
                          sx={{
                            "&:hover": {
                              color: palette.primary.light,
                              cursor: "pointer",
                            },
                          }}
                        >
                          {service.name}
                        </Typography>
                        <Typography color={'gray'} fontSize="0.75rem">
                          {service.description}
                        </Typography>
                      </Box>
                    </FlexBetween>
                    <FormControlLabel
                      control={
                        <Android12Switch
                          onClick={() => handleServiceSelection(service)}
                          checked={selectedServices?.some((s) => s._id === service._id)}
                        />
                      }
                    />

                  </FlexBetween>
                  {selectedServices?.some((s) => s._id === service._id) && (
                    <TextField
                      label={"Your Detail for " + selectedServices.find((s) => s._id === service._id).name + " (Optional)"}
                      value={selectedServices.find((s) => s._id === service._id).title}
                      onChange={(e) => handleTitleChange(e, service._id)}
                      fullWidth
                      margin="normal"
                    />
                  )}
                </>
              ))}
            </Box>

            <Typography
              color={palette.neutral.dark}
              variant="h5"
              fontWeight="700"
              marginTop={'10px'}
              marginBottom={'20px'}
            >
              Service Pet Selection
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
            {petstype &&
  petstype.map((pet) => (
    <React.Fragment key={pet._id}>
      <FlexBetween sx={{ width: "100%" }}>
        <FlexBetween gap="1rem">
          <UserImage image={pet.picturePath} size="55px" />
          <Box>
            <Typography
              color="black"
              variant="h5"
              fontWeight="500"
              sx={{
                "&:hover": {
                  color: palette.primary.light,
                  cursor: "pointer",
                },
              }}
            >
              {pet.petType}
            </Typography>
          </Box>
        </FlexBetween>
        <FormControlLabel
          control={
            <Android12Switch
              onClick={() => handlePetTypeSelection(pet)}
              checked={selectedServiceTypes?.some((type) => type.petType === pet._id)}
            />
          }
        />
      </FlexBetween>
      <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />
    </React.Fragment>
  ))}
            </Box>




          </>

          <Box sx={[{
            display: "flex", justifyContent: "space-between",

          },
            //  !isNonMobileScreens && {position:"absolute",bottom:'20px',width:"80%",}
          ]}>

            <Button
              variant="outlined"
              size="large"
              onClick={() => {

                handleClose();

              }}
            >
              {"Cancel"}
            </Button>
            <Button variant="contained" size="large" onClick={handleSave}>
              {"Save"}
            </Button>
          </Box>
        </Box></>

    </Modal>
  );
};

export default EditProfileModal;
