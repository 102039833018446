import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

const overlayService = createApi({
    reducerPath: 'overlaySteps',
    tagTypes: ['overlaySteps'],
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://api.petzoy.in/api/',
        prepareHeaders: (headers, {getState}) => {
            const reducers = getState();
            const token = reducers?.authReducer?.adminToken;
            headers.set('authorization', token ? `Bearer ${token}` : '');
            return headers;
        }
    }),
    endpoints: (builder) => {
        return {
            // Create a new overlay step
            createOverlayStep: builder.mutation({
                query: (data) => {
                    return {
                        url: '/overlaysteps',
                        method: 'POST',
                        body: data
                    };
                },
                invalidatesTags: ['overlaySteps']
            }),

            // Update an existing overlay step
            updateOverlayStep: builder.mutation({
                query: (data) => {
                    const {id, ...rest} = data;
                    return {
                        url: `/overlaysteps/${id}`,
                        method: 'PUT',
                        body: rest
                    };
                },
                invalidatesTags: ['overlaySteps']
            }),

            // Delete an overlay step
            deleteOverlayStep: builder.mutation({
                query: (id) => {
                    return {
                        url: `/overlaysteps/${id}`,
                        method: 'DELETE'
                    };
                },
                invalidatesTags: ['overlaySteps']
            }),

            // Get overlay steps by page with status true
            getOverlayStepsByPage: builder.query({
                query: (page) => {
                    return {
                        url: `/overlaysteps/page/${page}`,
                        method: 'GET'
                    };
                },
                providesTags: ['overlaySteps']
            }),

            // Get a specific overlay step by ID
            getOverlayStepById: builder.query({
                query: (id) => {
                    return {
                        url: `/overlaysteps/${id}`,
                        method: 'GET'
                    };
                },
                providesTags: ['overlaySteps']
            })
        };
    }
});

export const { 
    useCreateOverlayStepMutation, 
    useUpdateOverlayStepMutation, 
    useDeleteOverlayStepMutation, 
    useGetOverlayStepsByPageQuery, 
    useGetOverlayStepByIdQuery 
} = overlayService;

export default overlayService;
