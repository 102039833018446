import { Virtual, Navigation } from "swiper/modules";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import UserImage from "../UserImage";
import Moment from "react-moment";
// import './nearby.css'

const NearbyPets = ({ data }) => {
  const navigate = useNavigate();
 
  let i = 1;
  return (
    data?.length > 0 && (
      <Swiper
        navigation={true}
        modules={[Navigation]}
        spaceBetween={15}
        slidesPerView={10}
        Navigation
        className="w-full"
        breakpoints={{
          0: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 2,
          },
          1080: {
            slidesPerView: 5,
          },
          1280: {
            slidesPerView: 5,
          },
        }}
      >
        {data.map((category, index) => {
          if (i >= 5) {
            i = 1;
          } else {
            i++;
          }
          return (
            <SwiperSlide
              className="w-full h-full rounded-lg overflow-hidden overflow-hidden relative"
              key={index}
              virtualIndex={index}
            >
              <Link
                to={{
                  pathname: `/contactprofile/${category._id}`,
                }}
                state={{
                  userId: category._id,
                }}
                // to={`/shop/${category.name}`}
                className="w-full h-full"
              >
                <div class="petcard">
                <div className="petImageContainer">
                  <img
                    class="card-image"
                    src={`https://api.petzoy.in/assets/${category?.picturePath}`}
                    alt="Dog"
                  />
                </div>
                  <div class="card-content">
                    <div className="userMainHeading">
                      <h6 class="button">{category?.name}</h6>
                      <img
                        class="user-Image"
                        src={`https://api.petzoy.in/assets/${category?.owner?.picturePath}`}
                        alt="Dog"
                      />
                    </div>
                    <div class="card-info">
                    {!category?.private ? <span class="card-icon flex">
                        {category?.private &&  <i class="fa-solid fa-lock"/>} {"  "}
                        
                          <img
                          
                          src={`https://api.petzoy.in/assets/${category?.image}`}
                          style={{width:"20px",height:"20px"}}
                        />
                        {" "}
                        {" " +category?.breed}
                      </span>:
 <i class="fa-solid fa-lock"/>
        }
                      {!category?.private &&   <span class="card-date">
                        <i
                          class={
                            category?.gender == "male"
                              ? "fa-solid fa-mars "
                              : "fa-solid fa-venus"
                          }
                        ></i>{" "}
                                      <Moment
        fromNow
        style={{
          color: "#505050",
          fontSize: "10px",
        }}
      >
        {category?.age}
      </Moment>{" "}
                      </span>}
                    </div>
                  </div>
                </div>
              </Link>
            </SwiperSlide>
          );
        })}
         <SwiperSlide className="w-full h-full rounded-lg overflow-hidden relative">
        <Link to="/allnearbypets" className="w-full h-full flex items-center justify-center bg-gray-200">
          <h3 className="text-xl font-bold text-gray-800">View All</h3>
        </Link>
      </SwiperSlide>
      </Swiper>
    )
  );
};

export default NearbyPets;
