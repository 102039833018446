import { configureStore } from "@reduxjs/toolkit";
import authService from "./services/authService";
import categoryService from "./services/categoryService";
import productService from "./services/productService";
import brandService from "./services/brandService";
import paymentService from "./services/paymentService";
import authReducer from "./reducers/authReducer";
import globalReducer from "./reducers/globalReducer";
import cartReducer from "./reducers/cartReducer";
import homeProducts from "./services/homeProducts";
import orderService from "./services/orderService";
import userOrdersService from "./services/userOrdersService";
import { setupListeners as setupRTKQueryListeners } from "@reduxjs/toolkit/query/react";
import addressService from "./services/addressService";
import contactService from "./services/contactService";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import serviceService from "./services/serviceService";
import petService from "./services/pettypeService";
import tagService from "./services/discussiontagsService";
import discussionPosts from "./services/discussionPosts";
import allpetService from "./services/allpetService";
import bannerImageService from "./services/bannerImageService";
import contactUsService from "./services/contactusService";
import friendRequestService from "./services/FriendRequestService";
import reviewService from "./services/userReviewService";
import blogService from "./services/blogService";
import reportService from "./services/reportService";
import subcategoryService from "./services/subcategoryService";
import advertisementService from "./services/advertiseService";
import overlayService from "./services/overlayService";
const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const persistedReducer = persistReducer(persistConfig, authReducer);
const Store = configureStore({
  reducer: {
    [authService.reducerPath]: authService.reducer,
    [addressService.reducerPath]: addressService.reducer,
    [advertisementService.reducerPath]: advertisementService.reducer,
    [categoryService.reducerPath]: categoryService.reducer,
    [subcategoryService.reducerPath]: subcategoryService.reducer,
    [productService.reducerPath]: productService.reducer,
    [brandService.reducerPath]: brandService.reducer,
    [homeProducts.reducerPath]: homeProducts.reducer,
    [paymentService.reducerPath]: paymentService.reducer,
    [orderService.reducerPath]: orderService.reducer,
    [userOrdersService.reducerPath]: userOrdersService.reducer,
    [contactService.reducerPath]: contactService.reducer,
    [serviceService.reducerPath]: serviceService.reducer,
    [petService.reducerPath]: petService.reducer,
    [allpetService.reducerPath]: allpetService.reducer,
    [tagService.reducerPath]: tagService.reducer,
    [discussionPosts.reducerPath]: discussionPosts.reducer,
    [bannerImageService.reducerPath]: bannerImageService.reducer,
    [contactUsService.reducerPath]: contactUsService.reducer,
    [friendRequestService.reducerPath]: friendRequestService.reducer,
    [reviewService.reducerPath]: reviewService.reducer,
    [blogService.reducerPath]: blogService.reducer,
    [reportService.reducerPath]: reportService.reducer,
    [overlayService.reducerPath]: overlayService.reducer,
    authReducer: persistedReducer,
    global: globalReducer,
    cart:cartReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      [categoryService.middleware,
      productService.middleware,
      subcategoryService.middleware,
      brandService.middleware,
      homeProducts.middleware,
      paymentService.middleware,
      orderService.middleware,
      userOrdersService.middleware,
      addressService.middleware,
      serviceService.middleware,
      contactService.middleware,
      petService.middleware,
      tagService.middleware,
      allpetService.middleware,
      discussionPosts.middleware,
      bannerImageService.middleware,
      contactUsService.middleware,
      friendRequestService.middleware,
      reviewService.middleware,
      blogService.middleware,
      advertisementService.middleware,
      reportService.middleware,
      overlayService.middleware,
      authService.middleware]
    
    ),
});

export default Store;

export let persistor = persistStore(Store);
