import { useEffect, useState } from "react";
import Header from "../../components/Header";
import WhiteHeader from "../../components/WhiteHeader";
import { useZIM } from "../../hooks/zim";
import { useUserLoginMutation } from "../../store/services/authService";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { setLogin, setUserToken } from "../../store/reducers/authReducer";
import OTPInput from "react-otp-input";

function Login() {
  const latlong = useSelector((state) => state.authReducer.latlong);
    const [{callID}, zimAction] = useZIM();
  // ============= Initial State Start here =============
  const [email, setEmail] = useState("");
  const [name, setname] = useState('')
  const [password, setPassword] = useState("");
  const [location, setlocation] = useState('')
  // ============= Initial State End here ===============
  // ============= Error Msg Start here =================
  const [errEmail, setErrEmail] = useState("");
  const [errName, setErrName] = useState("");
  const [errPassword, setErrPassword] = useState("");

  const [successmessage, setsuccessmessage] = useState('')

  // ============= Error Msg End here ===================
  const [successMsg, setSuccessMsg] = useState("");
  // ============= Event Handler Start here =============
  const handleEmail = (e) => {

    if(showotpinput){
      setshowotpinput(false)
      
    }
    const maxLength = countryCodeMaxLength[countryCode] || 10; // Default to 10 if not found
    const value = e.target.value;

    if (value.length > maxLength) {
      setErrEmail(`Phone number cannot exceed ${maxLength} digits.`);
    } else {
      setEmail(e.target.value);
      setErrEmail("");
    }

  };


  const handleCountryCodeChange = (e) => {
    setCountryCode(e.target.value);
    setEmail(''); // Clear the phone number when the country code changes
    setErrEmail(''); // Clear any existing error
  };




  const [countryCode, setCountryCode] = useState('91'); // Default to India

  // Country code to max length map
  const countryCodeMaxLength = {
    '91': 10, // India
    '92': 10, // Pakistan
    '880': 10, // Bangladesh
    '977': 10, // Nepal
    '94': 10, // Sri Lanka
    '971': 9,  // UAE (Dubai)
    '1': 10,  // USA/Canada
    '44': 10, // UK
    // Add other country codes as needed
  };


  const handleName = (e) => {
  
    setname(e.target.value);
    setErrName("");
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
    setErrPassword("");
  };

  function errors(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  const [loginUser, response] = useUserLoginMutation()
  
  const [identifier, setIdentifier] = useState(''); 

  
  const registernewuser = async (e) => {
    e.preventDefault();
    if (name.length < 9 ) {
      setErrEmail("Enter your Name ");
    }
    if (!email) {
      setErrEmail("Enter your mobile number");
    }

    // ============== Getting the value ==============
    if (name) {
      const apiformData = new FormData();

      
      apiformData.append("firstName", name);
      apiformData.append("phone", email);
      apiformData.append("email", "");
      apiformData.append("picture", "");
      apiformData.append("location", location);
      apiformData.append("latitude", latlong.latitude);
      apiformData.append("longitude", latlong.longitude);
      // apiformData.append("picturePath", formData.picture.name);

      const savedUserResponse = await fetch(
        "https://api.petzoy.in/auth/register",
        {
          method: "POST",
          body: apiformData,
        }
      );
      const savedUser = await savedUserResponse.json();
      if (savedUser?.errors?.length > 0) {
        toast.error(savedUser.errors[0].msg);
        return;
      }

      if (savedUser) {
        zimAction
          .login({
            userID: savedUser._doc._id,
            userName: savedUser._doc.name,
          })
          .then(() => {
            zimAction.initEvent();
            zimAction.updateUserInfo(
              savedUser._doc.name,
              `https://api.petzoy.in/assets/${savedUser._doc?.picturePath}`
            );
            // alert("user login done")
          });
        dispatch(
          setLogin({
            user: savedUser._doc,
            token: savedUser.token,
          })
        );
        console.log("dispatch hit with data", savedUser._doc);
        localStorage.setItem("userToken", savedUser?.token);

        dispatch(setUserToken(savedUser.token));

        toast.success("Welcome to Petzoy");
        setTimeout(() => {
          navigate("/editprofile");
        }, 1000);
      }

    
    }
  };




  function getLocationInfo(latitude, longitude) {
    
    const url = `https://api.petzoy.in/reverse-geocoding?lat=${latitude}&lon=${longitude}&format=json&language=en`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.address) {
          setlocation(data.display_name);
          // console.log("results:", data.display_name,data.address.county,data.address.suburb,data.address.state_district,data.address.state);
        } else {
          console.log("Reverse geolocation request failed.");
        }
      })
      .catch((error) => console.error(error));
  }
  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };
  function success(pos) {
    var crd = pos.coords;
    console.log("Your current position is:");
    console.log(`Latitude : ${crd.latitude}`);
    console.log(`Longitude: ${crd.longitude}`);
    console.log(`More or less ${crd.accuracy} meters.`);

    getLocationInfo(crd.latitude, crd.longitude);
  }


  useEffect(() => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          console.log(result);
          if (result.state === "granted") {
            //If granted then you can directly call your function here
            navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === "prompt") {
            //If prompt then the user will be asked to give permission
            navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === "denied") {
            //If denied then you have to show instructions to enable location
          }
        });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);



  const [otp, setotp] = useState(0)
  // ============= Event Handler End here ===============
  const handleSignUp = (e) => {
    e.preventDefault();
    if (email.length < 9 ) {
      setErrEmail("Enter valid mobile number");
    }
    if (!email) {
      setErrEmail("Enter your mobile number");
    }

    // ============== Getting the value ==============
    if (email ) {

      if (window.verifyOtp) {
        console.log(JSON.stringify(window.verifyOtp))
        window.verifyOtp(
          otp,
          (data) => {console.log('OTP verified: ', data);
            loginUser({
              phone: email,
              password: password
          });
          
          },
          (error) =>{ console.log(error);toast.error("OTP Mismatch")}
        );
      } else {
        console.error('verifyOtp function is not available.');
        toast.error("OTP Error")
      }

    
    }
  };


  const [show_registermobile, setshow_registermobile] = useState(false)

  useEffect(() => {
    if(response.isError) {
      
      if(response?.error?.data?.errors[0].msg.includes('is not found!')){
        setsuccessmessage("Welcome New user please enter your name to Continue")
        setshow_registermobile(true)
      }else{
      toast.error(response?.error?.data?.errors[0].msg);
    }
  }
   }, [response?.error?.data])
   const dispatch = useDispatch();
   const navigate = useNavigate();
   useEffect(() => {
    if(response.isSuccess) {
      console.log(response)
      zimAction
      .login({userID: response.data.user._id, userName: response.data.user.name})
      .then(() => {
        zimAction.initEvent();
        zimAction.updateUserInfo(response.data.user.name, `https://api.petzoy.in/assets/${response.data.user?.picturePath}`);
        // alert("user login done")
      });
      dispatch(
        setLogin({
          user: response.data.user,
          token: response.data.token,
        })
      );
        console.log("dispatch hit with data",response.data.user)
      localStorage.setItem('userToken', response?.data?.token);
     
      dispatch(setUserToken(response?.data?.token))
      
      toast.success('Welcome User !');
      navigate('/');
    }
  }, [response.isSuccess])




  const [showotpinput, setshowotpinput] = useState(false)
  
  const [randomotp, setrandomotp] = useState(0)

  const [timer, setTimer] = useState(30); // Timer for resend button
  const [resendDisabled, setResendDisabled] = useState(false); // Disable resend button

  useEffect(() => {
    let interval;
    if(timer == 1){
      setResendDisabled(false)
    }
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timer]);



    const handleSubmit = async (e) => {
      e.preventDefault();
      if (!email) return;
      if (email.length < 9 ) {
        setErrEmail("Enter valid mobile number");
        return
      }
      setResendDisabled(true); // Disable resend button
      setTimer(30);
      setotp(0)
  
      if (window.sendOtp) {
        
        window.sendOtp(
          `${countryCode}${email}`,
          (data) => console.log('OTP sent successfully.', data),
          (error) => console.log('Error occurred', error)
        );
      } else {
        console.error('sendOtp function is not available.');
      }
  
      // Make a request to your backend to send OTP
      // const response = await fetch('https://api.petzoy.in/send-otp', {
      //     method: 'POST',
      //     headers: { 'Content-Type': 'application/json' },
      //     body: JSON.stringify({ phone: email })
      // });
      // const data = await response.json();
  
      // Handle the response, e.g., show OTP input field
      // console.log(data);
      // setrandomotp(data.otp);
      setshowotpinput(true);
  };





    
    return(
<div>
<Toaster position="top-right" />
<main class="fix">


    
    <section class="registration__area-two">
        <div class="container">
            <div class="registration__inner-wrap-two">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="registration__form-wrap">
                            <form onSubmit={(e) => {showotpinput ? show_registermobile ? registernewuser(e): handleSignUp(e):handleSubmit(e)}} class="registration__form">
                                <h3 class="title">Login Now</h3>
                                <span>Your email address will not be published. Required fields are marked *</span>
                              {show_registermobile ?
                            <div class="row gutter-20">
                                   
                            <div class="col-md-8">
                                <div class="form-grp">
                                    <input  onChange={handleName}
            value={name} type="text" placeholder="Name"/>
                                    {errName && (
            <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
              <span className="font-bold italic mr-1">!</span>
              {errName}
            </p>
          )}
                                    {successmessage && (
            <p className="text-sm text-green-500 font-titleFont font-semibold px-4">
              
              {successmessage}
            </p>
          )}
                                    {errName && (
            <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
              <span className="font-bold italic mr-1">!</span>
              {errName}
            </p>
          )}
                                </div>
                            </div>  
                            </div>:  
                             <div class="row gutter-20">
                                     {/* <div className="col-md-4">
        <div className="form-grp">
          <select
            value={countryCode}
            onChange={handleCountryCodeChange}
            className="form-control"
          >
            <option value="91">+91 (India)</option>
            <option value="92">+92 (Pakistan)</option>
            <option value="880">+880 (Bangladesh)</option>
            <option value="977">+977 (Nepal)</option>
            <option value="94">+94 (Sri Lanka)</option>
            <option value="971">+971 (UAE/Dubai)</option>
            <option value="1">+1 (USA/Canada)</option>
            <option value="44">+44 (UK)</option>
          </select>
        </div>
      </div>
                             <div class="col-md-8">
                                 <div class="form-grp">
                                     <input  onChange={handleEmail}
             value={email} type="number" placeholder="Phone Number"/>
                                     {errEmail && (
             <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
               <span className="font-bold italic mr-1">!</span>
               {errEmail}
             </p>
           )}
                                 </div> */}



<div className="col-md-8">
        <div className="form-grp relative">
          <div className="absolute inset-y-0 left-0 flex items-center">
            <select
              value={countryCode}
              onChange={handleCountryCodeChange}
              className="h-full py-0 pl-3 pr-8 text-gray-700 bg-white border-transparent focus:outline-none focus:ring-0 focus:border-gray-200 rounded-full"
              style={{ minWidth: '80px' }}
            >
              <option value="91">+91 (IN)</option>
              <option value="92">+92 (PK)</option>
              <option value="880">+880 (BD)</option>
              <option value="977">+977 (NP)</option>
              <option value="94">+94 (LK)</option>
              <option value="971">+971 (UAE)</option>
              <option value="1">+1 (US/CA)</option>
              <option value="44">+44 (UK)</option>
            </select>
          </div>
          {/* <input
            type="number"
            placeholder="Phone Number"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            className="pl-20 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent w-full"
            
          /> */}
  <input  onChange={handleEmail}
  className=" pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent w-full"
  style={{paddingLeft:"150px"}}
             value={email} type="number" placeholder="Phone Number" maxLength={countryCodeMaxLength[countryCode]}
            //  className="pl-20 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent w-full"
             />


          
        </div>
      
        {errEmail && (
            <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
              <span className="font-bold italic mr-1">!</span>
              {errEmail}
            </p>
          )}
                             </div>
                             {showotpinput && 
                  
                  <>
                  
                  <OTPInput
value={otp}
onChange={setotp}
numInputs={4}
// renderSeparator={<span>-</span>}
renderInput={(props) => <input {...props}  style={{
 width: '50px', // Adjust the width as needed
 height: '50px', // Adjust the height as needed
 fontSize: '20px', // Adjust the font size as needed
 textAlign: 'center', // Center the text horizontally
 marginLeft:'10px',marginRight:'10px'
}} />}
/>
{timer > 0 && (
     <p>Resend OTP in {timer} seconds</p>
   )}
   {timer === 0 && (
     <button className="btn" onClick={() => handleSubmit()} disabled={resendDisabled}>
       Resend OTP
     </button>
   )}
</>
}
                            
                            
                         </div>
                            } 
                            



                                <button type="submit" class="btn">Login <img src="assets/img/icon/right_arrow.svg" alt=""  class="injectable"/></button>
                                <p className="text-sm mt-10 font-titleFont font-medium">
                  Don't have an Account?{" "}
                  <Link to="/register">
                    <span className="hover:text-blue-600 duration-300">
                      Sign up
                    </span>
                  </Link>
                </p>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="registration__img">
                            <img src="assets/img/images/registration_img.png" alt="" data-aos="fade-right" data-aos-delay="400"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    

</main>

</div>

  );
}

export default Login;
