import {
    Avatar,
    Box,
    Button,
    Chip,
    Divider,
    Modal,
    TextField,
    Typography,
    useTheme,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    useMediaQuery,
    MenuItem,
    FormControlLabel,
    Checkbox,
  
  } from "@mui/material";
  import { useCallback, useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
import { useUpdateProfileimgMutation } from "../../store/services/authService";
import { setUserPicturePath } from "../../store/reducers/authReducer";
import Cropper from "react-easy-crop";
import Dropzone from "react-dropzone";
import FlexBetween from "../../components/FlexBetween";
import {
  EditOutlined,
  DeleteOutlined,
  AttachFileOutlined,
  GifBoxOutlined,
  ImageOutlined,
  MicOutlined,
  MoreHorizOutlined,
  VideocamOutlined,
  AddCircleOutlineOutlined,
  UploadFile,
} from "@mui/icons-material";
  
  const EditProfileImg = ({ open, setOpen }) => {
    const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
    const dispatch = useDispatch();
    const { _id, picturePath } = useSelector((state) => state.authReducer.user);
  
    const [preview, setPreview] = useState(null);
    const [imgfile, setimgfile] = useState(null);
  
    const { palette } = useTheme();
    const handleClose = () => {
     
      setOpen(false);
  };
  
  
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const [updateProfile, { isLoading, error }] = useUpdateProfileimgMutation(); // Use the mutation hook

    const handleImageUpload = (acceptedFiles) => {
      const file = acceptedFiles[0];
    if (file) {
      setimgfile(file);
      setPreview(URL.createObjectURL(file));
    }
  };

  

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });

  const getCroppedImg = async (imageSrc, pixelCrop) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      }, 'image/jpeg');
    });
  };

  const handleSave = async () => {
    if (!imgfile || !imgfile.type.startsWith("image/")) {
      console.error("Please select a valid image file.");
      return;
    }

    if (!croppedAreaPixels) {
      console.error("No cropped image data available.");
      return;
    }

    try {
        const croppedBlob = await getCroppedImg(preview, croppedAreaPixels);
        const randomName = `croppedImage_${Math.random().toString(36).substring(2, 15)}.jpg`;
        const croppedFile = new File([croppedBlob], randomName, { type: "image/jpeg" });

      const formData = new FormData();
      formData.append("userId", _id);
      formData.append("picture", croppedFile);
      formData.append("picturePath",randomName);

      const result = await updateProfile(formData);
      if (result?.data?.resp === true) {
        dispatch(setUserPicturePath({ picturePath: croppedFile.name }));
      }

      setimgfile(null);
      setPreview(null);
      handleClose();
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "scroll",
          bgcolor: "rgba(0,0,0,0)",
          border: "2px solid #000",
          flexDirection:"column",
          boxShadow: 24,
        }}
      >
        <>      {!isNonMobileScreens &&
   <Box
          sx={{
            width: "100%",
            maxWidth: "800px",
            bgcolor: palette.primary.main,
        display:"flex",
            boxShadow: 24,
            height:  "70px",alignItems:"center",justifyContent:"space-between",paddingLeft:'10px',paddingRight:'10px'
          }}
        >
          <i onClick={handleClose} class="fa-solid fa-chevron-left"></i>
           <Typography variant="h4" color={"black"}>
                      Edit Profile Image
                    </Typography>
                    <i class="fa-solid fa-paw"></i>
        </Box> }
        <Box
          sx={{
            width: isNonMobileScreens ? "30%" : "100%",
            maxWidth: "800px",
            bgcolor: "white",
            padding: 5,
            borderRadius: isNonMobileScreens ? 5:0,
            boxShadow: 24,
            height: isNonMobileScreens ? "auto" : "100%",
          }}
        >
          
         
  
         
  
          
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
               <Box
              sx={{
                width: "100%",
                height: "300px", // Fixed height for the cropper container
                position: "relative",
                alignSelf: "center",
                alignItems: "center",
                justifyContent: "center",
                borderWidth: 1,
                borderRadius: "10px",
                borderStyle: "dashed",
                overflow: "hidden",
                border: `2px dashed ${palette.primary.main}`
              }}
            >
              {/* <input type="file" onChange={handleImageUpload} /> */}
                {/* <Box mt="1rem" p="1rem"> */}
                  {!preview&&(
                    <Dropzone
                      acceptedFiles={".jpg,.jpeg,.png"}
                      multiple={false}
                      onDrop={handleImageUpload}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <FlexBetween>
                          <Box
                            {...getRootProps()}
                          
                            p="1rem"
                            width="100%"
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            textAlign="center"
                            bgcolor={palette.background.paper}
                            color={palette.text.primary}
                            sx={{
                              "&:hover": { cursor: "pointer", backgroundColor: palette.action.hover },
                              transition: "background-color 0.3s ease-in-out",
                              height:"300px"
                            }}
                          >
                            <input {...getInputProps()} />
                            {!preview ? (
                              <>
                                <UploadFile fontSize="large" style={{ fontSize: "48px", marginBottom: "1rem" }} />
                                <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                                  Upload or Drop Image Here (only .jpg,.jpeg,.png)
                                </p>
                              </>
                            ) : (
                              null
                            )}
                          </Box>
                          {/* {media && (
                          <IconButton
                            onClick={() => setMedia(null)}
                            sx={{ width: "15%" }}
                          >
                            <DeleteOutlined />
                          </IconButton>
                        )} */}
                        </FlexBetween>
                      )}
                    </Dropzone>
                  )}
                    {preview && (
                      <Cropper
                        image={preview}
                        crop={crop}
                        zoom={zoom}
                        aspect={3 / 4}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        style={{ containerStyle: { width: '100%', height: '300px' } }}
                      />
                    )}
                {/* </Box> */}
              
            </Box>
               
              <Typography>Upload Image</Typography>
              <Box>
               
              </Box>
            </Box>
          
  
        
     
  
          <Box sx={[{ display: "flex", justifyContent: "space-between",
          
           },!isNonMobileScreens && {position:"absolute",bottom:'20px',width:"80%"}]}>
           
            <Button
              variant="outlined"
              size="large"
              onClick={() => {
             
                setPreview(null);
    setimgfile('');
    handleClose();
              }}
            >
              {"Cancel"}
            </Button>
            <Button variant="contained" size="large" onClick={() => handleSave()}>
              {"Update"}
            </Button>
          </Box>
        </Box></>
  
      </Modal>
    );
  };
  
  export default EditProfileImg;
