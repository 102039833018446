import React, { useState } from 'react';
import { Tooltip, Zoom, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { useSelector } from 'react-redux';
import HoroscopeModel from '../WhiteHeader/HoroscopeModel/HoroscopeModel';

const FloatingButton = () => {
  const theme = useTheme();
  const isAuth = useSelector((state) => Boolean(state.authReducer.token));
  const [isHoroscopeModelOpen, setIsHoroscopeModelOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (!isAuth) return null;

  return (
    <>
      <Tooltip 
        title="Fun Zone" 
        placement={isMobile ? "top" : "left"} 
        arrow 
        TransitionComponent={Zoom}
      >
        <button
          onClick={() => setIsHoroscopeModelOpen(true)}
          className="fixed bottom-20 right-4 z-[1000] w-16 h-16 p-0 rounded-full overflow-hidden shadow-lg transition-all duration-300 ease-in-out hover:scale-110 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 md:bottom-10 md:right-10 md:w-20 md:h-20 animate-floating"
          aria-label="Open Fun Zone"
        >
          <div className="absolute inset-0 bg-gradient-to-br from-primary-400 to-primary-600 opacity-90"></div>
          <img 
            src="https://animalzone.pk/wp-content/uploads/2024/01/logo-animalzone.png" 
            alt="Fun Zone"
            className="relative w-full h-full object-cover transition-transform duration-300 ease-in-out hover:rotate-12"
          />
          <span className="absolute bottom-0.5 left-0 right-0 text-[10px] md:text-xs text-white font-bold text-center">Fun Zone</span>
          <span className="sr-only">Open Fun Zone</span>
        </button>
      </Tooltip>
      <HoroscopeModel open={isHoroscopeModelOpen} setOpen={setIsHoroscopeModelOpen} />
    </>
  );
};

export default FloatingButton;
