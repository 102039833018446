import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetPolicypagesQuery } from "../../store/services/authService";
import { Link, useNavigate } from "react-router-dom";
import { useGetContactUsByIdQuery } from "../../store/services/contactusService";
import { logout } from "../../store/reducers/authReducer";
import { useMediaQuery } from "@mui/material";
import ReactGA from 'react-ga4';

const Footer = () => {
  const dispatch = useDispatch();
  const isAuth = Boolean(useSelector((state) => state.authReducer.token));
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const navigate = useNavigate();
  // const { data, isLoading } = useGetPolicypagesQuery();
  const { data, isLoading, error } = useGetPolicypagesQuery();

  React.useEffect(() => {
    if(isAuth){

      if (error && (error.status === 401 || error.status === 500)) {
        handleLogout();
      }
    }
  }, [error]);

  const handleLogout = () => {
    dispatch(logout("userToken"));
    navigate("/"); // Update the route as needed
  };

  const { contactusid } = useSelector((state) => state.authReducer);
  const { data: contactusdata, isFetching: contactusfetch } = useGetContactUsByIdQuery(contactusid);

  // Google Analytics
  const handleClick = (action, label) => {
    ReactGA.event({
      category: 'Mobile-Footer',
      action: action,
      label: label
    });
  };

  return (
    <footer>
      <div
        class="footer__area"
        style={{ paddingBottom: isNonMobileScreens ? "0px" : "80px" }}
      >
        <div class="footer__bottom">
          <div class="container">
            <div class="row align-items-center">
              <div
                class={`footer__social ${
                  isNonMobileScreens ? "col-lg-4" : "flex justify-between"
                }`}
              >
                <h6 class="title">Follow Us On:</h6>
                <ul class="list-wrap">
                  <li>
                    <a href={contactusdata?.facebookPageLink} target="_blank">
                      <i class="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href={contactusdata?.twitterPageLink} target="_blank">
                      <i class="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href={`https://api.whatsapp.com/send?phone=${contactusdata?.whatsappNumber}&text=${contactusdata?.whatsappMessage}`}
                      target="_blank"
                    >
                      <i class="fab fa-whatsapp"></i>
                    </a>
                  </li>
                  <li>
                    <a href={contactusdata?.instagramPageLink} target="_blank">
                      <i class="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href={contactusdata?.youtubePageLink} target="_blank">
                      <i class="fab fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-lg-4">
                <div class="footer__bottom-menu">
                  <ul class="list-wrap">
                    {data?.map((item, index) => (
                      <li key={index}>
                        <Link
                          to={`/docs/${item.name}`}
                          state={{ value: item.value }}
                        >
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="copyright-text text-end">
                  <p>Copyright © 2024. All Rights Reserved.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav class="mobile-bottom-nav">
        <div class="mobile_bottom_nav_container">
          <div class="row">
            <div class="col">
              <div class="mobile_bottom_nav_item">
                <div class="top_border"></div>
                <a
                  href="/"
                  class="mobile_bottom_nav_item_content active"
                  id="home-nav"
                  onClick={() => handleClick('Navigation', 'Nearby')}
                >
                  <i class="fa-solid fa-house "></i>
                  <div class="content">Nearby</div>
                </a>
              </div>
            </div>
            <div class="col">
              <div class="mobile_bottom_nav_item">
                <a
                  href="/shops"
                  class="mobile_bottom_nav_item_content "
                  id="internships-nav"
                  onClick={() => handleClick('Navigation', 'Shop')}
                >
                  <i class="fa-solid fa-paw"></i>
                  <div class="content">Shop</div>
                </a>
              </div>
            </div>
            {isAuth && (
              <div class="col">
                <div class="mobile_bottom_nav_item">
                  <a
                    href="/home"
                    class="mobile_bottom_nav_item_content "
                    id="jobs-nav"
                    onClick={() => handleClick('Navigation', 'Social')}
                  >
                    <i class="fa-solid fa-dog"></i>
                    <div class="content">Social</div>
                  </a>
                </div>
              </div>
            )}
            <div class="col">
              <div class="mobile_bottom_nav_item">
                <a
                  href="/discussiondashboard"
                  class="mobile_bottom_nav_item_content "
                  id="courses-nav"
                  onClick={() => handleClick('Navigation', 'Petopedia')}
                >
                  <div id="nav_bar_bottom_amber_dot" class="amber_dot"></div>
                  <i class="fa-solid fa-comments"></i>
                  <div class="content">Petopedia</div>
                </a>
              </div>
            </div>
            {isAuth && (
              <div class="col">
                <div class="mobile_bottom_nav_item">
                  <a
                    href="/editprofile"
                    class="mobile_bottom_nav_item_content "
                    id="courses-nav"
                    onClick={() => handleClick('Navigation', 'Account')}
                  >
                    <div id="nav_bar_bottom_amber_dot" class="amber_dot"></div>
                    <i class="fa-solid fa-gear"></i>
                    <div class="content">Account</div>
                  </a>
                </div>
              </div>
            )}
            {!isAuth && (
              <div class="col">
                <div class="mobile_bottom_nav_item">
                  <a
                    href="/signin"
                    class="mobile_bottom_nav_item_content "
                    id="courses-nav"
                    onClick={() => handleClick('Navigation', 'Login')}
                  >
                    <div id="nav_bar_bottom_amber_dot" class="amber_dot"></div>
                    <i class="fa-solid fa-gear"></i>
                    <div class="content">Login</div>
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>
    </footer>
  );
};

export default Footer;
