import { Link, useLocation, useParams } from "react-router-dom";
import { useGetBrandQuery } from "../../store/services/brandService";
import Friend from "../../components/Friend";



function CommercialDetails() {
    const { _id } = useParams();
    
    const { data, isFetching } = useGetBrandQuery(_id);

    return (
        <body>
            <main class="fix">
                <section class="breadcrumb__area fix">
                    <div class="container">
                        <div class="row align-items-end">
                            <div class="col-lg-8">
                                <div class="breadcrumb__content">
                                    <h3 class="title">Business Details</h3>
                                    <nav class="breadcrumb">
                                        <span property="itemListElement" typeof="ListItem">
                                            <a href="index.html">Home</a>
                                        </span>
                                        <span class="breadcrumb-separator"><i class="flaticon-right-arrow-angle"></i></span>
                                        <span property="itemListElement" typeof="ListItem">Business Details</span>
                                    </nav>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="breadcrumb__img">
                                    <img src="assets/img/images/breadcrumb_img.png" alt="img" data-aos="fade-left" data-aos-delay="800" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div class="breadcrumb__shape-wrap">
                        <img src="assets/img/images/breadcrumb_shape01.png" alt="img" data-aos="fade-down-right" data-aos-delay="400" />
                        <img src="assets/img/images/breadcrumb_shape02.png" alt="img" data-aos="fade-up-left" data-aos-delay="400" />
                    </div> */}
                </section>

                <section class="team__details-area">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-5 col-md-8">
                                <div class="team__details-img">
                                    <img src={`https://api.petzoy.in/assets/${data?.picturePath}`} alt="img" />
                                </div>
                            </div>
                            <div class="col-lg-7">
                                <div class="team__details-content">
                                    <h2 class="name">{data?.name}</h2>
                                    <span>{data?.type}</span>
                                    <p>{data?.description}</p>
                                    <div class="experience-wrap mb-5">
                                        <h4 class="title">Owner:</h4>
                                        <Friend
                                            friendId={data?.owner?._id}
                                            name={`${data?.owner?.firstName}`}
                                            subtitle={data?.owner?.occupation}
                                            userPicturePath={data?.owner?.picturePath}
                                        />
                                        {/* <p>when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries but also thep into electronic typesetting, remaining essentially.</p> */}
                                    </div>
                                    <div class="contact__info-wrap team__details-info">
                                        <h6 class="title">Information:</h6>
                                        <ul class="list-wrap">
                                            <li>
                                                <div class="icon">
                                                    <i class="flaticon-phone"></i>
                                                </div>
                                                <a href="tel:0123456789">{data?.phone}</a>
                                            </li>
                                            <li>
                                                <div class="icon">
                                                    <i class="flaticon-placeholder"></i>
                                                </div>
                                                {data?.location}
                                            </li>
                                            <li>
                                                <div class="icon">
                                                    <i class="flaticon-mail"></i>
                                                </div>
                                                <a href="mailto:info@gmail.com">{data?.email}</a>
                                            </li>
                                            <li>
                                                <div class="icon">
                                                    <i class="fas fa-share-alt"></i>
                                                </div>
                                                <ul class="list-wrap contact__social">
                                                    <li><a href="https://www.facebook.com/" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                                    <li><a href="https://twitter.com" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                                    <li><a href="https://www.whatsapp.com/" target="_blank"><i class="fab fa-whatsapp"></i></a></li>
                                                    <li><a href="https://www.instagram.com/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                                                    <li><a href="https://www.youtube.com/" target="_blank"><i class="fab fa-youtube"></i></a></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* {maindata?.type == "NGO" &&<div className="product__details-qty mt-20">
                        <Link to={{ pathname: '/allproducts' }} state={{maincat :{name:"Pet Gift"}}} className="add-btn">
                      Donate Now
                    </Link></div>}
                       */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </main>

        </body>
    );
}

export default CommercialDetails;
